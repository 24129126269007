import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StaticImage } from "gatsby-plugin-image"
import { faFacebook, faTumblr, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

const Header = () => {
    return (
        <>
        <div className="m-auto mb-16 mt-4">
                <div className="max-w-5xl m-auto text-center items-center flex justify-between py-2">
                    <img className="w-36" src="final-desktop-logo.svg" alt="hello logo" />
                    <div className="flex justify-between w-40 text-2xl">
                        <div><FontAwesomeIcon icon={faFacebook} /></div>
                        <div><FontAwesomeIcon icon={faTwitter} /></div>
                        <div><FontAwesomeIcon icon={faTumblr} /></div>
                        <div><FontAwesomeIcon icon={faYoutube} /></div>
                    </div>
                </div>

                <div className="h-11 text-center" style={{background : '#00897B'}}>
                    <ul className="flex max-w-5xl h-11 items-center m-auto text-white text-lg leading-4">
                        <li className="pr-4">Home</li>
                        <li className="px-4">Best Picks</li>
                        <li className="px-4">Knowledge base</li>
                        <li className="pl-4">News</li>
                    </ul>
                </div>


                <div className="bg-gray-200  mt-7 max-w-5xl m-auto">
                
                <ul className="flex items-center  text-lg">
                        <li className="pr-4">Trending</li>
                        <li className="px-4">Pick a Motherboard</li>
                        <li className="px-4">AM3+ CPUs</li>
                        <li className="pl-4">X470 Motherboards</li>
                    </ul>

                </div>

                <aside className="m-auto max-w-5xl mt-7  mb-2 leading-4">
                    <small>Final Desktop supported by its audience. When you purchase through our links, we may earn an affiliate commission at no additional cost to you.</small>
                </aside>

                {/* <ol className="m-auto max-w-5xl flex mt-7  mb-2 leading-4">
                    <li>
                        <a href="/">Home </a>
                    </li>
                    <li>
                        <a href="/graphics-cards/">
                            >  graphics-cards
                        </a>
                    </li>
                </ol> */}


            </div>
        </>
    )
}

export default Header
