import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import Header from "./header"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      {/* <header className="global-header">
        {isHomePage ? (
          <h1 className="main-heading">
            <Link to="/">{parse(title)}</Link>
          </h1>
        ) : (
          <Link className="header-link-home" to="/">
            {title}
          </Link>
        )}
      </header> */}

      <Header />

      <main className="m-auto max-w-5xl">{children}</main>

      <footer className="mt-12 p-12 text-white" style={{background: '#333333'}}>
        <div className="max-w-5xl m-auto">
          <p>Final Desktop is part of Steel Brain Inc. a leading digital publisher.</p>
          <div className="mx-3 bg-white" style={{height : '1px'}}></div>
          <ul className="flex justify-between flex-wrap">
            <li className="mx-12">Terms and Conditiond</li>
            <li className="mx-12">Privacy Policy</li>
            <li className="mx-12">Cookies Policy</li>
            <li className="mx-12">Affialate Disclouser</li>
            <li className="mx-12">Advertise</li>
            <li className="mx-12">About us</li>
            <li className="mx-12">Contact Us</li>
          </ul>
          <div className="mx-3 bg-white" style={{height : '1px'}}></div>
          <p>© {new Date().getFullYear()}, Steel Brain, Inc.</p>

        </div>
      </footer>
    </div>
  )
}

export default Layout
